






















































































import {applicationStore} from '@/store/modules/application';
import {Component, Prop, Vue} from 'vue-property-decorator';
import ToolTip from "@/components/custom/ToolTip.vue";
import {tourStore} from "@/store/modules/tour";
import {profileStore} from "@/store/modules/profile";

@Component({
  name: 'base-navigation-page',
  components: {
    ToolTip
  },
})
export default class BaseNavigationPage extends Vue {
  @Prop({default: 'Title'}) title?: string;
  @Prop({default: []}) items?: any[];

  get businessName() {
    return applicationStore.businessName;
  }

  onNavItemSelected(navItem) {
    if (navItem.selected) {
      return;
    }
    if (!navItem.group) {
      this.$emit('nav-item-selected', navItem);
    }
  }

  onSubnavItemSelected(parentNavItem: any, navItem: any) {
    if (navItem.selected) {
      return;
    }
    this.$emit('nav-item-selected', navItem);
  }
}
