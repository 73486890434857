









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ToolTipHint from "@/components/custom/ToolTipHint.vue";

@Component({
  name: 'tool-tip',
  components: { ToolTipHint }
})
export default class ToolTip extends Vue {
  @Prop({type: String, default: ''}) hint
  @Prop({type: String, default: ''}) maxWidth
  @Prop({type: Boolean, default: false}) fixedTip

  hover: boolean = false
  child: any | null = null

  @Watch('hover')
  onHover(after) {
    if (after) {
      if (this.child || this.hint==='') {
        return
      }
      const tooltip = this.$refs.tooltip as HTMLElement;
      const rect = tooltip.getBoundingClientRect()
      const toolTipHint = Vue.extend(ToolTipHint)
      this.child = new toolTipHint({
        propsData: {
          text: this.hint,
          rect: rect,
          maxWidth: this.maxWidth,
          fixedTip: this.fixedTip
        }
      }).$mount()
      this.appRoot?.appendChild(this.child.$el)
    } else {
      this.dismiss()
    }
  }

  get appRoot() {
    return document.getElementById('app')
  }

  dismiss(event: any = null) {
    if (this.child) {
      this.appRoot?.removeChild(this.child?.$el)
      this.child?.$destroy()
      this.child = null
    }
  }
}
