import {BusinessContact, PersonalContactItem} from '@/domain/model/types';
import {ContactsRepository} from '@/data/contactsDataRepository';
import {inject, injectable, singleton} from 'tsyringe';

@injectable()
@singleton()
export class AddContactToGroupUseCase {
    private contactsRepository: ContactsRepository;

    constructor(@inject('ContactsRepository') contactsRepository: ContactsRepository) {
        this.contactsRepository = contactsRepository;
    }

    invoke = async (groupContact: BusinessContact, newContacts: BusinessContact[]): Promise<BusinessContact | null> => {
        const newItems = newContacts.map((item) => {
            const contactItem: PersonalContactItem = {
                id: item.id!,
                name: item.name,
                email: item.email!,
                position: item.position!,
                associate: item.associate
            };
            if (item.photoUrl) {
                contactItem.photoUrl = item.photoUrl;
            }
            if (item.phoneNumber) {
                contactItem.phoneNumber = item.phoneNumber;
            }
            return contactItem;
        });

        groupContact.contacts!.push(...newItems);
        groupContact.associateIDs!.push(...newItems.map((item) => item.associate!.id!));
        return await this.contactsRepository.update(groupContact);
    };
}
