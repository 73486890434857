




import {Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {mixins} from 'vue-class-component';
import Navigation from '@/mixins/Navigation';
import {applicationStore} from '@/store/modules/application';
import {archiveStore} from '@/store/modules/archive/ArchiveStore';
import {chatStore} from '@/store/modules/chat/ChatStore';
import {channelsStore} from '@/store/modules/channels';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave'])

@Component({
  name: 'chats',
  components: {BaseNavigationPage}
})
export default class Chats extends mixins(Navigation) {
  @Getter activeCountAll;
  @Getter activeCountPersonal;
  @Getter innerCountAll;
  @Getter innerCountPersonal;
  @Getter showInnerBadge
  @Getter showActiveBadge

  get navItemArchive() {
    const query = this.$route.query;
    if (applicationStore.isAdmin) {
      return {
        title: 'Archive',
        type: 'archive',
        name: 'archive',
        counter: archiveStore.archiveCount,
        group: true,
        expanded: true,
        children: [
          {
            title: 'All',
            params: {archiveId: ''},
            query: {type: 'archive', subtype: 'all'},
            type: 'archive',
            name: 'archive',
            counter: archiveStore.archiveCountAll,
            selected: query.type === 'archive' && query.subtype === 'all'
          },
          {
            title: 'Mine',
            params: {archiveId: ''},
            query: {type: 'archive', subtype: 'personal'},
            type: 'archive',
            name: 'archive',
            counter: archiveStore.archiveCountPersonal,
            selected: query.type === 'archive' && query.subtype === 'personal'
          }
        ]
      }
    }
    return {
      title: 'Archive',
      type: 'archive',
      name: 'archive',
      params: {archiveId: ''},
      query: {type: 'archive', subtype: 'personal'},
      counter: archiveStore.archiveCountPersonal,
      selected: query.type === 'archive' && query.subtype === 'personal'
    }
  }

  get navItemBusiness() {
    const query = this.$route.query;
    if (applicationStore.isAdmin) {
      const {activeCountAll, innerCountAll} = this
      return {
        title: 'All',
        counter: activeCountAll + innerCountAll,
        group: true,
        expanded: true,
        children: [
          {
            title: 'External',
            type: 'active',
            name: 'active',
            params: {chatId: ''},
            query: {type: 'active', subtype: 'all'},
            counter: activeCountAll,
            selected: query.type === 'active' && query.subtype === 'all'
          },
          {
            title: 'Internal',
            type: 'inner',
            name: 'inner',
            params: {chatId: ''},
            query: {type: 'inner', subtype: 'all'},
            counter: innerCountAll,
            selected: query.type === 'inner' && query.subtype === 'all'
          }
        ]
      }
    }
    const {activeCountPersonal, showActiveBadge} = this
    return {
      title: 'External',
      type: 'active',
      name: 'active',
      params: {chatId: ''},
      query: {type: 'active', subtype: 'personal'},
      counter: activeCountPersonal,
      innerBadge: showActiveBadge,
      selected: query.type === 'active' && query.subtype === 'personal'
    }
  }

  get navItemPersonal() {
    const query = this.$route.query;
    const {activeCountPersonal, innerCountPersonal, showActiveBadge, showInnerBadge} = this
    if (applicationStore.isAdmin) {
      return {
        title: 'My chats',
        counter: activeCountPersonal + innerCountPersonal,
        group: true,
        expanded: true,
        children: [
          {
            title: 'External',
            type: 'active',
            name: 'active',
            params: {chatId: ''},
            query: {type: 'active', subtype: 'personal'},
            counter: activeCountPersonal,
            innerBadge: showActiveBadge,
            selected: query.type === 'active' && query.subtype === 'personal'
          },
          {
            title: 'Internal',
            type: 'inner',
            name: 'inner',
            params: {chatId: ''},
            query: {type: 'inner', subtype: 'personal'},
            counter: innerCountPersonal,
            innerBadge: showInnerBadge,
            selected: query.type === 'inner' && query.subtype === 'personal'
          }
        ]
      }
    }
    return {
      title: 'Internal',
      type: 'inner',
      name: 'inner',
      params: {chatId: ''},
      query: {type: 'inner', subtype: 'personal'},
      counter: innerCountPersonal,
      innerBadge: showInnerBadge,
      selected: query.type === 'inner' && query.subtype === 'personal'
    }
  }

  get navItems() {
    const {
      navItemBusiness,
      navItemPersonal,
      navItemArchive
    } = this;
    return [
      navItemBusiness,
      navItemPersonal,
      {
        title: 'Channels',
        type: 'channel',
        name: 'channels',
        counter: channelsStore.channelsCount,
        selected: (this.$route.name === 'channels' || this.$route.name === 'channel-detail')
      },
      navItemArchive
    ];
  }

  async onNavItemSelected(navItem) {
    if (navItem.name === 'active' || navItem.name === 'inner') {
      const chatId = await chatStore.findChatIdForType({
        type: navItem.query.type,
        subtype: navItem.query.subtype
      })
      if (chatId) {
        await this.$router.push({name: `${navItem.name}-chat`, params: {chatId}, query: navItem.query});
        return
      }
    // todo: add redirect to Content Not Found (404)
    }
    if (navItem.name === 'archive') {
      const archiveId = await archiveStore.findArchiveIdForType({
        type: navItem.query.type,
        subtype: navItem.query.subtype
      })
      if (archiveId) {
        await this.$router.push({name: 'archive', params: {archiveId}, query: navItem.query});
        return
      } else {
        await this.$router.push({name: `archive`, params: {'archiveId': 'empty'}, query: navItem.query});
        return
      }
    }
    await this.$router.push({name: navItem.name, params: navItem.params, query: navItem.query});
  }

  created() {
    this.selectPage(3);
  }

  async beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter :: ', to.name)
    if (to.params.chatId) {
      if (await chatStore.checkChatById(
          {
            chatId: to.params.chatId,
            type: to.query.type,
            subtype: to.query.subtype
          })) {
        next()
      } else if (to.name === 'active-chat') {
        next({name: 'active', query: to.query})
      } else if (to.name === 'inner-chat') {
        next({name: 'inner', query: to.query})
      } else {
        next()
      }
    } else if(to.params.archiveId) {
      next()
    } else {
      const chatId = await chatStore.findChatIdForType({
        type: to.query.type,
        subtype: to.query.subtype
      })
      if (chatId && await chatStore.checkChatById({chatId, type: to.query.type, subtype: to.query.subtype})) {
        if (to.query.type === 'active') {
          next({name: 'active-chat', params: {chatId}, query: to.query})
        } else {
          next({name: 'inner-chat', params: {chatId}, query: to.query})
        }
      } else {
        next()
      }
    }
  }

  beforeRouteLeave(to, from, next) {
    chatStore.lastChatRoute(from.fullPath)
    next()
  }
}
