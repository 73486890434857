








import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ToolTipHint extends Vue {
  @Prop({type: String, default: ''}) text
  @Prop() rect: any
  @Prop({type: String, default: ''}) maxWidth
  @Prop({type: Boolean, default: false}) fixedTip

  width: number = 0
  height: number = 0
  show: boolean = false
  adjustTop: number = 10

  mounted() {
    if(!this.fixedTip){
      if ((window.innerWidth <= 800) || (window.innerHeight <= 600)) return
    }    
    this.show = true
    this.$nextTick(function () {
      const tooltip = this.$refs.hint as HTMLElement
      if (tooltip) {
        const rect = tooltip.getBoundingClientRect()
        this.width = rect.width
        this.height = rect.height
      }
    })
  }

  get checkPosition() {
    return this.height && this.rect.top - this.height - 10 > 0
  }

  get style() {
    let styleContainer: string[] = []
    if (this.maxWidth) {
      styleContainer.push(`max-width: ${this.maxWidth}px;`)
    }
    if (this.checkPosition) {
      const styleTop = this.rect.top - this.height - this.adjustTop
      styleContainer.push(`top: ${styleTop}px;`)
    } else {
      const styleTop = this.rect.bottom + this.adjustTop
      styleContainer.push(`top: ${styleTop}px;`)
    }
    const stylePreLeft: number = Math.floor(this.rect.left+(this.rect.right-this.rect.left)/2-this.width/2)
    const widthWindow: number = window.innerWidth || document.documentElement.clientWidth
    let styleLeft: number = stylePreLeft
    let triangle: number = 0
    if (stylePreLeft+this.width > widthWindow) {
      triangle =  - (stylePreLeft+this.width - widthWindow + 5)
    } else if (stylePreLeft < 0) {
      triangle = -1 * stylePreLeft + 5
    }
    styleLeft = stylePreLeft + triangle
    styleContainer.push(`left: ${styleLeft}px; --hint-triangle: ${triangle}px`)
    return styleContainer.join(' ')
  }

  get hintPosition() {
    if(this.fixedTip){
      let scrollTop = 0;
      const scrollBody = document.querySelector("#body-wrapp");
      if(scrollBody){
        scrollTop = scrollBody.scrollTop;
      }

      this.adjustTop = 2 - scrollTop;
      return 'hint-fixed';
    }
    return this.checkPosition ? 'hint hint-top' : 'hint hint-bottom'
  }
}
