import {DocumentData, setDoc} from 'firebase/firestore'
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  updateProfile
} from 'firebase/auth'
import {auth} from './firebase.init';
import {dynamicLinkConfig} from '@/plugins/firebase.config';
import {user} from '@/data/firebase';

export const authUser = () => auth.currentUser

export function signIn(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password);
}

export async function createUser(fullName: string, email: string, password: string) {
  const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
  if (!!getAdditionalUserInfo(userCredentials)?.isNewUser) {
    const currentUser = userCredentials.user;
    if (currentUser) {
      const userData = {
        fullName,
        email: currentUser.email,
        owner: true,
        superAdmin: true,
        type: 1 // ASSOCIATE
      } as DocumentData;
      await Promise.all([
        updateProfile(currentUser, {displayName: fullName, photoURL: null}),
        setDoc(user(currentUser.uid), userData)
      ]);
    }
  }
  return userCredentials;
}

export function sendSignInLink(email: string) {
  return sendSignInLinkToEmail(auth, email, dynamicLinkConfig.actionCodeSettings);
}

export function signOutCurrent() {
  return signOut(auth);
}

export async function completeSignUp(fullName: string, email: string, password: string) {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const userData: DocumentData = {
      fullName,
      email,
      owner: true,
      superAdmin: true,
      type: 1 // ASSOCIATE
    };
    await Promise.all([
      updateProfile(currentUser, {displayName: fullName, photoURL: null}),
      setDoc(user(currentUser.uid), userData)
    ]);
    await updatePassword(currentUser, password);
    const credential = await reauthenticateWithCredential(
      currentUser,
      EmailAuthProvider.credential(email, password));
    return credential.user;
  } else {
    throw new Error('Auth failed. User is not present');
  }
}

export async function signInWithGooglePopUp() {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  // This gives you a Google Access Token. You can use it to access the Google API.
  // const token = result.credential.accessToken;
  // The signed-in user info.
  const currentUser = result.user;
  if (currentUser) {
    if (!!getAdditionalUserInfo(result)?.isNewUser) {
      const userData = {
        fullName: currentUser.displayName,
        email: currentUser.email,
        photoUrl: {thumbnail: currentUser.photoURL, normal: currentUser.photoURL},
        owner: true,
        admin: true,
        type: 1 // ASSOCIATE
      };
      await setDoc(user(currentUser.uid), userData)
    }
    return currentUser;
  } else {
    throw new Error('Auth failed. User is not present');
  }
}

export function confirmResetPassword(code: string, password: string) {
  return confirmPasswordReset(auth, code, password);
}
