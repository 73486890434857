import {BusinessContact, Contact, PersonalContactItem} from '@/domain/model/types';
import {ContactsRepository} from '@/data/contactsDataRepository';
import {ContactPhotoRepository} from '@/data/contactPhotoDataRepository';
import {inject, injectable, singleton} from 'tsyringe';

@injectable()
@singleton()
export class CreateGroupContactUseCase {
    private contactsRepository: ContactsRepository;
    private photoRepository: ContactPhotoRepository;

    constructor(@inject('ContactsRepository') contactsRepository: ContactsRepository,
                @inject('ContactPhotoRepository') photoRepository: ContactPhotoRepository) {
        this.contactsRepository = contactsRepository;
        this.photoRepository = photoRepository;
    }

    invoke = async (contacts: BusinessContact[], contactData: Contact): Promise<BusinessContact | null> => {
        const associates = contacts.map((item) => item.associate!);
        const newContact: BusinessContact = {
            contacts: contacts.map((item) => {
                const contactItem: PersonalContactItem = {
                    id: item.id!,
                    name: item.name,
                    associate: item.associate,
                };
                if (item.email) {
                    contactItem.email = item.email;
                }
                if (item.position) {
                    contactItem.position = item.position;
                }
                if (item.photoUrl) {
                    contactItem.photoUrl = item.photoUrl;
                }
                if (item.phoneNumber) {
                    contactItem.phoneNumber = item.phoneNumber;
                }
                return contactItem;
            }),
            business: contacts[0].business!,
            name: contactData.name,
            description: contactData.description || '',
            position: contactData.position || '',
            email: contactData.email || '',
            phoneNumber: contactData.phoneNumber || '',
            type: 2,
            associateIDs: associates.map((item) => item.id!),
            rules: {
                NOTIFICATION: {
                    notify: true,
                    delay: 0
                },
                VISIBILITY: {
                    visible: true
                },
                CONVERSATION: {
                    type: 1
                }
            },
            flatIndex: 0
        };

        const directoryContacts = await this.contactsRepository.findAll(contacts[0].business!.id!);
        newContact.flatIndex = directoryContacts.length;

        const contact = await this.contactsRepository.save(newContact);
        if (!!contactData && !!contactData.photoFile) {
            const downloadUrl = await this.photoRepository.save(contacts[0].business!.id!, {
                contactId: contact.id,
                file: contactData.photoFile
            });
        }
        return contact;
    };
}
